import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { CommonPose, VisbilityContainer } from '../../../../../home/Animations'
import styled from 'styled-components'
import {
  ChaliceImg,
  Container,
  Content,
  interpolate,
  VerticalPad
} from '../../../../../theme/default/base'
import { MainCta } from '../../../../../theme/default/common'
import PropTypes from 'prop-types'
import { getImageUrl, getOpacity, hasImage } from '../../utils'

export const SideImage = inject('store')(
  observer(
    ({
      id,
      store,
      title,
      description,
      image,
      reverse = false,
      bg_color = '#fff',
      text_color = 'black',
      text_shadow = false,
      has_cta = true,
      currentComponent = {},
      isPreview
    }) => {
      const { siteData } = store
      let left = (
        <>
          <CommonPose>
            <SideImageHeader
              dangerouslySetInnerHTML={{
                __html: interpolate(store.siteData, title || '')
              }}
            />
          </CommonPose>
          <CommonPose
            dangerouslySetInnerHTML={{
              __html: interpolate(siteData, description)
            }}
          />
          <ActionRow>
            <MainCta hasCta={has_cta} isPreview={isPreview} />
          </ActionRow>
        </>
      )
      let right = (
        <>
          <CommonPose>
            {hasImage(image) && (
              <ChaliceImg
                key={id}
                src={getImageUrl(image)}
                opacity={getOpacity(image)}
              />
            )}
          </CommonPose>
        </>
      )
      if (reverse) {
        const tmp = right
        right = left
        left = tmp
      }
      return (
        <VisbilityContainer>
          <SideImageBlock
            textColor={text_color}
            textShadow={text_shadow}
            backgroundColor={bg_color}
          >
            <Container>
              <VerticalPad>
                <Content>
                  <SideImageLeft> {left} </SideImageLeft>
                  <SideImageRight> {right} </SideImageRight>
                </Content>
              </VerticalPad>
            </Container>
          </SideImageBlock>
        </VisbilityContainer>
      )
    }
  )
)

SideImage.propTypes = {
  bgColor: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.any,
  reverse: PropTypes.bool,
  cta: PropTypes.any
}

const SideImageBlock = styled.div`
  background-color: ${(props) => props.backgroundColor || '#fff'};

  color: ${(props) => props.textColor};
  text-shadow: ${(props) =>
    props.textShadow ? '2px 2px rgba(0, 0, 0, 0.3)' : null};

  h1,
  h2,
  h3,
  h4 {
    color: ${(props) => props.textColor};
    text-shadow: ${(props) =>
      props.textShadow ? '2px 2px rgba(0, 0, 0, 0.3)' : null};
  }

  h2 {
    font-size: 1.2rem;
  }

  img {
    width: 100%;
    height: auto;
    transition: all 0.25s;
  }
`

const SideImageLeft = styled.div`
  width: 50%;
  padding-right: 50px;

  & > div {
    padding-bottom: 20px;
  }

  .action-row {
    ${(props) => props.theme.media.tablet`
      flex-direction: row;
    `}
  }

  ${(props) => props.theme.media.tablet`
    width: 100%;
    padding: 50px 0;
  `}
`

const SideImageRight = styled.div`
  padding-left: 50px;
  width: 50%;

  ${(props) => props.theme.media.tablet`
    width: 100%;
    padding: 0;
  `}
`

const SideImageHeader = styled.h2`
  font-size: 1.2rem;
`

const ActionRow = styled.div`
  margin: 20px 0;
`
